<template>
    <div class='phyRepotr'>
        <div class="title">身体地图—生理功能失衡矩阵</div>
        <div class="user">
            <div>姓名：{{userInfo.Ex1}}</div>
            <div>性别：{{userInfo.Ex3}}</div>
            <div>年龄：{{userInfo.Ex4}}</div>
        </div>
        <div class="content">
            <div id="main" class="radarChart"></div>
            <!-- 注解 -->
            <div class="annotation">
                <div class="anTitle">注解</div>
                <div class="info">
                    <div class="note" v-for="(item, index) in noteItem" :key="index">
                        <div class="noteimg">
                            <img class="img" :src="item.img" alt="">
                        </div>
                        <div>{{item.text}}</div>
                    </div>
                </div>
            </div>

            <div class="detail">
                <div class="detaItem" v-for="(item, index) in funItem" :key="index">
                    <div class="detitle">{{item.title}}</div>
                    <van-loading size="24px" v-if="loadding">加载中...</van-loading>
                    
                    <div class="detinfo" v-else>
                        <div class="infotext" v-if="item.quota">
                            <div class="text" v-for="(el, i) in item.data" :key="i">
                                <div v-if="el.text">
                                    {{el.title}}: {{el.text}}
                                </div>
                            </div>
                        </div>
                        <div class="good" v-else>
                            <div class="goodsimg">
                                <img class="img" :src="goodUrl" alt="">
                            </div>
                            <div>此系统无风险</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getReportData, getCoreReport } from '@/api/index'
import * as echarts from 'echarts/core';
import { TitleComponent, LegendComponent } from 'echarts/components';
import { RadarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

export default {
    components: {
        
    },
    data() {
        return {
            userInfo: {},
            loadding: false,
            funItem: [
                {
                    title: '一、消化与吸收',
                    data: []
                },
                {
                    title: '二、结构完整性',
                    data: []
                },
                {
                    title: '三、(内分泌)传导系统',
                    data: []
                },
                {
                    title: '四、(心血管)传输系统',
                    data: []
                },
                {
                    title: '五、解毒能力',
                    data: []
                },
                {
                    title: '六、能力生成',
                    data: []
                },
                {
                    title: '七、免疫与防御',
                    data: []
                },    
            ],
            goodUrl: `${this.$imgDomain}/www/HealthingCore/good.png`,
            noteItem: [
                {
                    img:  `${this.$imgDomain}/www/HealthingCore/note1.png`,
                    text: '此区域代表平衡，请继续保持好的生活方式'
                },
                {
                    img: `${this.$imgDomain}/www/HealthingCore/note2.png`,
                    text: '此区域代表轻度失衡，建议调整生活方式'
                },
                {
                    img: `${this.$imgDomain}/www/HealthingCore/note3.png`,
                    text: '此区域代表失衡加速中，建议提前干预或调整生活方式'
                },
                {
                    img: `${this.$imgDomain}/www/HealthingCore/note4.png`,
                    text: '此区域代表严重失衡，建议深度检查或及时干预'
                },
                {
                    img: `${this.$imgDomain}/www/HealthingCore/note5.png`,
                    text: '此区域代表完全失衡，建议深度检查或立即干预'
                },
            ],
            ifeelData: [],
            coreData: [],
            userId: '',

        };
    },
    props:{
        
    },
    created() {
        this.userId = this.$query('userId');
    },
    mounted() {
        this.getReportData();
    },
    methods: {
        // ifeel报告
        getReportData(){
            this.$toast.loading({
                duration: 5000,
                message: '加载中',
            })
            this.loadding = true;
            const formData = new FormData();
            formData.append('action', 'GetNewTotalReportByUserId');
            formData.append('userId', this.userId);
            getCoreReport(formData).then(res => {
                if(res && res.result){
                    this.userInfo = res.result.userExpands;
                    // ifeel 报告数据
                    this.ifeelData = res.result.iFeelReportInfo;
                    // core 报告数据
                    this.coreData = res.result.coreReportInfo;

                    // 处理数据
                    this.dataProcessing();

                } else {
                    this.$toast('无数据')
                }
            })
        },
        // 合并数组
        conCatArr(){
            const screen = [
                ['肠道','胃','肺','皮肤', '菌群'],
                ['骨骼','子宫','卵巢'],
                ['前列腺','卵巢','胰腺','甲状腺', '荷尔蒙平衡'],
                ['心血管'],
                ['肝脏','肾脏', '肝脏解毒', '雌激素代谢', '环境激素', '重金属代谢'],
                ['氧化压力','营养元素'],
                ['过敏']
            ];
            return new Promise(resolve => {
                const dataArr = this.ifeelData.concat(this.coreData);
                screen.forEach((el, index) => {
                    el.forEach(item => {
                        this.funItem[index].data.push({
                            title: item
                        })
                    })
                })
                setTimeout(() => {
                    resolve(dataArr)
                }, 1000)
            })
        },
        // 数据处理
        async dataProcessing(){
            this.conCatArr().then(dataArr => {
                if(dataArr) {
                    this.funItem.forEach(item => {
                        let quota = [];
                        item.data.forEach(el => {
                            let text = [];
                            dataArr.forEach(vla => {
                                if(vla.MainId.indexOf(el.title) != -1){
                                    text.push(vla.Ex3);
                                    
                                    el.text = text.join("; ");
                                }
                            })
                        })
                    })

                    this.loadding = false;
                    this.$toast.clear();
                    this.$forceUpdate();

                    
                    // 雷达数据处理
                    this.radarData(this.ifeelData);
                }
            })
        },
        // 雷达数据异步处理
        asyncRadarData(arr){
            return new Promise(resolve => {
                this.funItem.forEach(item => {
                    let quota = [];
                    item.data.forEach(el => {
                        arr.forEach(vla => {
                            if(vla.MainId.indexOf(el.title) != -1){
                                quota.push(vla.Ex1);
                                item.quota = quota;
                            }
                        })
                    })
                })
                resolve(this.funItem);
            })
        },
        // 雷达数据处理
        radarData(arr){
            this.asyncRadarData(arr).then(res => {
                let radarData = [];
                res.forEach(item => {
                    if(item.quota) {
                        const minMathData = Math.min.apply(Math, item.quota.map(el => { return el}));
                        if(minMathData <= 0.425) {
                            radarData.push(90);
                        } else if(minMathData > 0.425 && minMathData <= 0.6) {
                            radarData.push(70);
                        } else if(minMathData > 0.6 && minMathData <= 0.8) {
                            radarData.push(50);
                        } else {
                            radarData.push(20)
                        }
                    } else {
                        radarData.push(20)
                    }
                })
                radarData.splice(1, 1, 65)
                radarData.splice(2, 1, 65)
                this.$nextTick(() => {
                    this.radarChartConfig(radarData);
                })
            })
        },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        // 雷达图配置
        radarChartConfig(data){
            if(!document.getElementById('main')){
                return
            }
            echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);
            var option;
            try {
                let valueType = [
                    { name: '消化与吸收', value: '01', max: 100 },
                    { name: '解毒能力', value: '07', max: 100 },
                    { name: '能力生成', value: '06', max: 100 },
                    { name: '免疫与防御', value: '05', max: 100 },
                    { name: '传输系统(心血管)', value: '04', max: 100 },
                    { name: '传导系统(内分泌)', value: '03', max: 100 },
                    { name: '结构完整性', value: '02', max: 100 },
                ];
                option = {
                    radar: {
                        // shape: 'circle',
                        name: {
                            formatter: (value) => {
                                let d;
                                valueType.map((item) => {
                                    if (item.name == value) {
                                        d = item.value;
                                    }
                                });
                                return [`${d}`, `${value}`].join("\n");
                            },
                            // fontSize: 10,
                            // fontFamily: "Source Han Sans CN",
                            // fontWeight: "bold",
                            color: "#333333",
                        },
                        indicator: valueType,
                        radius: 90,//缩放
                        center: ['50%', '50%'],//位置
                        splitArea: {
                            areaStyle: {// 图表背景网格区域的颜色
                                color: ["#fff"],
                            }
                        },
                        splitLine : {
                            show : true,
                            lineStyle : {
                                width : 1,
                                // 图表背景网格线的颜色
                                color : ['#86272A', '#74A841', '#E8EA70', '#D89F15', '#B21E23', '#86272A', '#E8EA70']
                            }
                        }

                    },
                    series: [
                        {
                            name: 'Budget vs spending',
                            type: 'radar',
                            symbolSize: 3,//拐点大小
                            data: [
                                {
                                    value: data,
                                }
                            ],
                            lineStyle: {
                                width: 1,
                                color: '#5dc3dc'
                            },
                            itemStyle:{
                                normal:{
                                    color:'#5dc3dc',//拐点颜色
                                    borderColor:'#5dc3dc',//拐点边框颜色
                                },
                            }
                        }
                    ]
                };
            } catch {
                
            }
            option && myChart.setOption(option);
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.phyRepotr {
    height: 100%;
    padding: 10px;
    .title {
        height: 50px;
        border-radius: 20px 20px 0 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        letter-spacing: 5px;
        background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/ifeel1.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:100% 100%;
    }
    .user {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 50px;
        font-size: 14px;
        background: #F5F5F5;
    }
    .content {
        background: #F5F5F5;
        display: flex;
        flex-direction: column;
        height: calc(100% - 100px);
        overflow-y: scroll;
        .radarChart {
            width: 100%;
            min-height: 300px !important;
        }
        .annotation {
            width: 100%;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            .anTitle {
                width: 40px;
                height: 150px;
                border: 2px solid #e2e2e2;
                font-size: 20px;
                display: flex;
                align-items: center;
                line-height: 40px;
                text-align: center;
                color: #828282;
                margin-right: 10px;
            }
            .info {
                .note {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    color: #7e7f81;
                    .noteimg {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .detail {
            width: 100%;
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #606062;
            .detaItem {
                width: 90%;
                padding: 10px;
                border: 2px solid #5dc1db;
                background-color: #c9ebf4;
                min-height: 200px;
                margin-bottom: 10px;
                .detitle {
                    font-weight: 700;
                    font-size: 14px;
                }
                .detinfo {
                    .infotext {
                        padding-left: 30px;
                    }
                    .text {
                        margin: 5px 0;
                    }
                    .good {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 150px;
                        .goodsimg {
                            width: 50px;
                            height: 50px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            /deep/ .van-loading {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80%;
            }
        }
    }
}
</style>
